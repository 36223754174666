import React, { useEffect, useState } from 'react'

import { DEFAULT_MASKED_PASSWORD } from '../hooks/useEditCamera'
import { UseFormMethods } from 'react-hook-form'

import { Box, FormLabel, HStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { FormInputControl, FormInputSelectControl } from '@/components/ui'
import { DeviceCameraEditQuery } from '@/graphql/generated/operations'
import { StreamingType } from '@/graphql/generated/schemas'

import { CameraFormDataTypeI } from '../../types/types'
import {
  getRTSPPath,
  getStreamingProtocolOption,
  getStreamingTypeOption,
  streamingProtocols,
  streamingTypes,
  validateIPOrHostname,
  validatePort,
} from '../utils/utils'

interface EditCameraLiveStreamingFieldsIProps {
  cameraData: DeviceCameraEditQuery
  isLoading: boolean
  form: UseFormMethods<CameraFormDataTypeI>
}

export const EditCameraLiveStreamingFields = ({
  cameraData,
  isLoading,
  form,
}: EditCameraLiveStreamingFieldsIProps) => {
  const [isUsernameUpdated, setIsUsernameUpdated] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const { errors, register, control, trigger, getValues, setValue, watch } =
    form
  const { rtspPath, rtspIp, rtspPort, streamingProtocol, streamingType } =
    getValues([
      'rtspPath',
      'rtspIp',
      'rtspPort',
      'streamingProtocol',
      'streamingType',
    ])
  watch(['streamingProtocol', 'streamingType'])

  const setLiveStreamType = () => {
    setValue('streamingType', getStreamingTypeOption(cameraData))
  }

  const setRTSPCredentials = () => {
    setValue('rtspPath', cameraData?.device?.camera?.rtspPath)
    setValue('rtspUsername', cameraData?.device?.camera?.rtspUsername)
    setValue('rtspIp', cameraData?.device?.camera?.rtspIp)
    setValue('rtspPort', cameraData?.device?.camera?.rtspPort)
    setValue('streamingProtocol', getStreamingProtocolOption(cameraData))
    if (cameraData?.device?.camera?.rtspUsername) {
      // This is a default value to populate the input, its not actually used.
      setValue('rtspPassword', DEFAULT_MASKED_PASSWORD)
    }
    setFirstLoad(false)
  }

  const handleUsernameUpdated = () => {
    if (
      !isUsernameUpdated &&
      cameraData?.device?.camera?.rtspUsername !== getValues('rtspUsername')
    ) {
      // Username has been updated reset password field and mark as required
      register('rtspPassword', { required: true })
      setValue('rtspPassword', '')
      trigger(['rtspPassword'])
      setIsUsernameUpdated(true)
    }
  }

  useEffect(() => {
    setLiveStreamType()
  }, [])

  useEffect(() => {
    if (streamingType?.value === StreamingType.OnPremise && firstLoad) {
      setRTSPCredentials()
    }
  }, [streamingType?.value])

  return (
    <AnimatePresence key='editCamera'>
      <Box key='streamingType' mb='3'>
        <FormInputSelectControl
          control={control}
          data-testid='devicesPage_editCameraModal_streamingType'
          defaultValue={null}
          errorMessage='Select a streaming type'
          id='streamingType'
          isClearable
          isInvalid={!!errors?.streamingType}
          label='Streaming Type'
          options={streamingTypes}
          placeholder='Select Streaming Type'
          rules={{ required: true }}
        />
      </Box>
      {streamingType?.value === StreamingType.OnPremise && (
        <>
          <HStack align='stretch' key='streamingProtocol' mb='4' spacing='3'>
            <FormInputSelectControl
              control={control}
              data-testid='devicesPage_editCameraModal_streamingProtocol'
              defaultValue={null}
              errorMessage={!!errors?.streamingProtocol && 'Select Protocol'}
              id='streamingProtocol'
              isClearable
              isDisabled={isLoading}
              isInvalid={!!errors?.streamingProtocol}
              label='Protocol'
              options={streamingProtocols}
              placeholder='Select Protocol'
              rules={{ required: true }}
              tooltipText='Camera protocol'
            />
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspPath'
              errorMessage={errors.rtspPath?.message}
              id='rtspPath'
              inputRef={register({
                required: 'RTSP Path is required',
              })}
              isInvalid={Boolean(errors.rtspPath)}
              label='RTSP Path'
              onChange={() => trigger(['rtspPath'])}
              placeholder='Enter RTSP Path'
              tooltipText='Path of the RTSP feed'
            />
          </HStack>

          <HStack align='stretch' key='rtspIp' mb='4' spacing='3'>
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspIP'
              errorMessage={errors.rtspIp?.message}
              id='rtspIp'
              inputRef={register({
                validate: (v) => validateIPOrHostname(v),
              })}
              isInvalid={Boolean(errors.rtspIp)}
              label='RTSP IP/hostname'
              onChange={() => trigger(['rtspIp'])}
              placeholder='Enter RTSP IP'
              tooltipText='IP address or hostname of the RTSP feed'
            />
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspPort'
              errorMessage={errors.rtspPort?.message}
              id='rtspPort'
              inputRef={register({
                validate: (v) => validatePort(v),
              })}
              isInvalid={Boolean(errors.rtspPort)}
              label='RTSP Port'
              onChange={() => trigger(['rtspPort'])}
              placeholder='Enter RTSP Port'
              tooltipText='Port of the RTSP feed'
            />
          </HStack>
          <HStack align='stretch' key='rtspUsername' mb='4' spacing='3'>
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspUsername'
              errorMessage={errors.rtspUsername?.message}
              id='rtspUsername'
              inputRef={register({
                required: 'RTSP User Name is required',
              })}
              isInvalid={Boolean(errors.rtspUsername)}
              label='RTSP User Name'
              onChange={handleUsernameUpdated}
              placeholder='Enter RTSP User Name'
              tooltipText='User name for RTSP credentials'
            />

            <FormInputControl
              errorMessage={errors.rtspPassword?.message}
              id='rtspPassword'
              inputRef={register({
                required: 'RTSP User Password is required',
              })}
              isInvalid={Boolean(errors.rtspPassword)}
              label='RTSP Password'
              placeholder='Enter RTSP Password'
              tooltipText='Password for RTSP credentials'
              type='password'
            />
          </HStack>

          <Box mb={3}>
            <FormLabel
              color='#2D2E41'
              fontSize='16px'
              margin='0px 0px 8px'
              opacity={0.5}
            >
              RTSP URL
            </FormLabel>
            <Box
              color='#2D2E41'
              fontSize='14px'
              fontWeight='semibold'
              opacity={0.5}
            >
              {getRTSPPath(
                cameraData,
                streamingProtocol?.value,
                rtspPath,
                rtspIp,
                rtspPort
              )}
            </Box>
          </Box>
        </>
      )}
    </AnimatePresence>
  )
}
