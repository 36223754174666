import { FacilityType, IncidentType } from '@/graphql/generated/schemas'

export interface FormInputsIProps {
  name: string
  type: {
    label: string
    value: IncidentType | null
  }
  facilityOrigin: {
    label: string
    value: string
  }
  floorOrigin?: {
    label: string
    value: string
  }
  deviceOrigin?: {
    label: string
    value: string
  }
  description: string
  address?: {
    value: string
    latitude: number
    longitude: number
  }
  latitude?: number
  longitude?: number
}

export interface FacilityFormDataIProps {
  id: string
  type: FacilityType
}

export interface LatLngStateIProps {
  lat: number
  lng: number
}

export const latRegex = /^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/
export const lonRegex =
  /^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/
