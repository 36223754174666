import { IncidentType } from '@/graphql/generated/schemas'

const incidentTypeOptions = [
  { label: 'Device Health', value: IncidentType.DeviceHealth },
  { label: 'Manual', value: null },
]

export const getOptions = (hasNoLiveStream: boolean) => {
  return incidentTypeOptions.filter((option) => {
    if (hasNoLiveStream) {
      return option.value !== IncidentType.DeviceHealth
    }
    return option
  })
}
